import React from 'react'
import Head from 'components/elements/Head'

const Terms: React.FC = () => {
  return (
    <>
      <Head title='利用規約' />

      <h1>利用規約</h1>
      <p>
        この利用規約（以下、「当規約」といいます）は、当サイトの利用条件を定めるものです。ご利用ユーザーの皆さま（以下、「ユーザー」といいます）には、当規約に従って、当サイトをご利用いただきます。
      </p>

      <h2>適用</h2>
      <p>
        当規約は、ユーザーと当サイトとの間の当サイトの利用に関わる一切の関係に適用されるものとします。
      </p>

      <h2>禁止事項</h2>
      <p>ユーザーは、当サイトの利用にあたり、以下の行為をしてはなりません。</p>

      <ul>
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>当サイトのサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
        <li>当サイトのサービスの運営を妨害するおそれのある行為</li>
        <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
        <li>他のユーザーに成りすます行為</li>
        <li>
          当サイトのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
        </li>
        <li>その他、当サイトが不適切と判断する行為</li>
      </ul>

      <h2>当サイトの提供の停止等</h2>
      <p>
        当サイトは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく当サイトの全部または一部の提供を停止または中断することができるものとします。
      </p>

      <ul>
        <li>当サイトにかかるコンピュータシステムの保守点検または更新を行う場合</li>
        <li>
          地震、落雷、火災、停電または天災などの不可抗力により、当サイトの提供が困難となった場合
        </li>
        <li>コンピュータまたは通信回線等が事故により停止した場合</li>
        <li>その他、当サイトが当サイトの提供が困難と判断した場合</li>
      </ul>

      <p>
        当サイトは、当サイトの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
      </p>

      <h2>利用制限</h2>
      <p>
        当サイトは、以下の場合には、事前の通知なく、ユーザーに対して、当サイトの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
      </p>

      <ul>
        <li>当規約のいずれかの条項に違反した場合</li>
        <li>その他、当サイトが当サイトの利用を適当でないと判断した場合</li>
      </ul>

      <p>
        当サイトは、本条に基づき当サイトが行った行為によりユーザーに生じた損害について、一切の責任を負いません。
      </p>

      <h2>サービス内容の変更等</h2>
      <p>
        当サイトは、ユーザーに通知することなく、当サイトの内容を変更しまたは当サイトの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
      </p>

      <h2>利用規約の変更</h2>
      <p>
        当サイトは、必要と判断した場合には、ユーザーに通知することなくいつでも当規約を変更することができるものとします。
      </p>

      <h2>権利義務の譲渡の禁止</h2>
      <p>
        ユーザーは、当サイトの書面による事前の承諾なく、利用契約上の地位または当規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      </p>

      <h2>免責</h2>
      <p>当サイトは、次の各号の場合において、一切その責任を負わないものとします。</p>

      <ul>
        <li>
          当サイトを介して行う、第三者が提供するコンテンツのダウンロード、および、第三者が管理・運営するリンクサイトへのアクセス等の行為により、利用者に生じた損害
        </li>
        <li>
          当サイトが合理的な安全策を講じたにもかかわらず、当サイトの無断改変、当サイトに関するデータへの不正アクセス、コンピュータウィルスの混入等の不正行為が行われ、これに起因して利用者に生じた損害
        </li>
        <li>
          その他当サイトの利用（これらに伴う当サイトによる情報提供行為等を含みます）若しくは不利用により生じる一切の損害（精神的苦痛、またはその他の金銭的損失を含む一切の不利益）
        </li>
      </ul>

      <p>
        前項各号において、万一当サイトが責任を負う場合であっても、当サイトが責任を負うのは当サイトに故意または重過失がある場合に限るものとし、またその責任範囲は直接かつ通常の損害に限られるものとします。
      </p>

      <h2>準拠法・裁判管轄</h2>
      <p>
        当規約の解釈にあたっては、日本法を準拠法とします。当サイトに関して紛争が生じた場合には、当サイトの本店所在地を管轄する裁判所を専属的合意管轄とします。
      </p>
    </>
  )
}

export default Terms
