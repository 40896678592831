import React from 'react'
import Head from 'components/elements/Head'

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Head title='プライバシーポリシー' />

      <h1>プライバシーポリシー</h1>
      <p>
        当サイトは、当サイトが取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
      </p>

      <h2>個人情報の安全管理</h2>
      <p>
        当サイトは、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、当サイトの取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
      </p>

      <h2>個人情報の取得等の遵守事項</h2>
      <p>当サイトによる個人情報の取得、利用、提供については、以下の事項を遵守します。</p>

      <h3>個人情報の取得</h3>
      <p>
        当サイトは、当サイトが管理するインターネットによるサービスの運営に必要な範囲で、当サイトの一般利用者（以下「ユーザー」といいます。）又は当サイトに広告掲載を行う者（以下「掲載主」といいます。）から、ユーザー又は掲載主に係る個人情報を取得することがあります。
      </p>

      <h3>個人情報の利用目的</h3>
      <p>
        当サイトは、当サイトが取得した個人情報について、法令に定める場合又は本人の同意を得た場合を除き、以下に定める利用目的の達成に必要な範囲を超えて利用することはありません。
      </p>

      <ul>
        <li>当サイトの運営、維持、管理</li>
        <li>当サイトを通じたサービスの提供及び紹介</li>
        <li>当サイトの品質向上のためのアンケート</li>
      </ul>

      <h3>個人情報の提供等</h3>
      <p>
        当サイトは、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
      </p>

      <h3>個人情報の利用目的の変更</h3>
      <p>前項で特定した利用目的は変更される場合がございます。</p>

      <h3>個人情報の第三者提供</h3>
      <p>
        当サイトは、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
      </p>

      <h3>個人情報の取扱いの改善・見直し</h3>
      <p>
        当サイトは、個人情報の取扱い、管理体制及び取組みに関する点検を実施し、継続的に改善・見直しを行います。
      </p>

      <h3>個人情報の廃棄</h3>
      <p>
        当サイトは、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
      </p>

      <h3>苦情や相談の担当窓口</h3>
      <p>当サイトは、個人情報の取扱いに関する担当窓口及び責任者を以下の通り設けます。</p>
      <p style={{ overflowWrap: 'break-word' }}>
        https://tayori.com/form/67f023637e2b6edb5fd2b1c9a98ed26853f9f2f5/
      </p>
    </>
  )
}

export default PrivacyPolicy
