import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const usePageTracking = () => {
  const location = useLocation()
  const ga4TrackingId = process.env.REACT_APP_GA4_TRACKING_ID
  if (!ga4TrackingId) {
    return
  }

  useEffect(() => {
    ReactGA.initialize(ga4TrackingId)
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    })
  }, [location])
}

export default usePageTracking
